import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './ColdRooms.css';
import Footer from "../Footer";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import coldroom1 from "../../images/service/service24.jpg";
import coldroom2 from "../../images/service/service1.jpg";
import coldroom3 from "../../images/service/service2.jpg";
import coldroom4 from "../../images/service/service3.jpg";
import coldroom5 from "../../images/service123.jpg";
import coldroom6 from "../../images/service/service5.jpg";
import coldroom7 from "../../images/cold rooms/coldroom7.jpg";

import UpdateIcon from '@mui/icons-material/Update';
import HandymanIcon from '@mui/icons-material/Handyman';
import SettingsIcon from '@mui/icons-material/Settings';

const Maintenance = () => {

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const acItems = [
    {
      original: coldroom1,
      thumbnail: coldroom1,
      //description: "Offices",
    },
    {
      original: coldroom2,
      thumbnail: coldroom2,
      //description: "",
    },
    {
      original: coldroom3,
      thumbnail: coldroom3,
      //description: "Retail Stores",
    },
    {
      original: coldroom4,
      thumbnail: coldroom4,
      //description: "Bespoke Homes",
    },
    {
      original: coldroom5,
      thumbnail: coldroom5,
      //description: "Government Buildings",
    },
    {
      original: coldroom6,
      thumbnail: coldroom6,
      //description: "Data Centers Cooling",
    },
    {
      original: coldroom1,
      thumbnail: coldroom1,
      //description: "",
    },
    {
      original: coldroom7,
      thumbnail: coldroom7,
    },
  ];

  const topContainerRef = useRef(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 250);
    });

    const scrollToTopContainer = () => {
      window.scrollTo({
        top: topContainerRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    scrollToTopContainer();
  }, []);

  return (
    <div className="flex-col items-center card opacity-0">
      <div ref={topContainerRef} className="flex flex-col items-center pb-10 pt-20">
        <div className="topContainer">
          <h1 className="text-5xl font-thin md:text-7xl px-4">Service and Repairs</h1>
        </div>

        <div className="mt-8 mb-4 mx-4 lg:max-w-3xl shadow-xl rounded-xl overflow-hidden">
          <ImageGallery
            items={acItems}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={500}
            slideInterval={6000}
            showNav={false}
            showIndex={false}
            thumbnailPosition="bottom"
          />
        </div>

        <div className="text-center text-lg md:text-xl mb-8 p-4 xl:p-14 xl:px-18">


          <p className="p-4 font-light text-xl md:text-3xl text-red-500">
            "We are committed to Providing Cost-effective and Energy-efficient Solutions."
          </p>

          <div className="block sm:hidden items-center mt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          <div className="point-container flex flex-col sm:grid sm:grid-cols-2 gap-20 p-6 md:p-10">

            <div className="point mx-3 md:mx-10">
              <img src={coldroom2} alt="Commercial Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-md max-h-96  xl:w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Routine Maintenance</h3>
                <p className="text-justify">
                  Our team of experts is dedicated to keeping your equipment in top-notch condition. We offer routine maintenance services that include inspections, cleaning, and preventative measures to ensure your systems run smoothly year-round.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom4} alt="Residential Air Conditioning" className="float-left mr-10 mb-10 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Rapid Response Repairs</h3>
                <p className="text-justify">
                  When your refrigeration or air conditioning systems need immediate attention, Climac's rapid response team is at your service. We excel in swift diagnostics and efficient repairs, ensuring your equipment is back in action without delay.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom5} alt="Office Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Comprehensive Servicing</h3>
                <p className="text-justify">
                  Our comprehensive servicing solutions cover everything from system diagnostics to component replacements. We take a holistic approach to ensure all aspects of your equipment are functioning optimally, extending its lifespan and efficiency.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom3} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Equipment Upgrades</h3>
                <p className="text-justify">
                  Stay on the forefront of refrigeration and air conditioning technology with Climac's upgrade services. Our experts assess your systems, recommend advanced enhancements, and deliver superior performance, efficiency, and compliance with the latest industry standards.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center rounded-2xl overflow-auto">
            <h1 className="pt-6 pb-4 md:pt-10 text-4xl md:text-5xl px-3">
              Our Promise
            </h1>
            <p className="px-3 pb-6 md:px-3 md:pt-3 text-xl">
              At Climac, our pledge is simple yet steadfast: to deliver unmatched expertise and reliability in servicing and maintaining your refrigeration, air conditioning, and cold room systems. We ensure optimal performance, reduce energy costs, and extend equipment lifespan. Experience the Climac advantage – where satisfaction is guaranteed.
            </p>
            <div className='md:flex justify-center gap-5 shadow-lg pt-5 pb-10 md:py-10 md:pb-20'>
              <div className='flex-1 bg-gray-800 text-white shadow-md rounded-lg mx-6 md:mx-0 md:ml-10 lg:ml-16'>
                <UpdateIcon fontSize="large" className="animate-bounce mt-8 text-blue-500" />
                <h2 className="text-3xl mb-2 pt-4 px-3">24 hour Call-Outs</h2>
                <div className='m-5 pb-2 px-1 md:px-2 md:pb-4'>
                  <p className='mb-2 pb-2'>We're at your service 24/7, ready to swiftly address any emergency that arises. No matter when you need us, Climac is just a call away, ensuring your peace of mind.</p>
                </div>
              </div>

              <div className='flex-1 bg-gray-800 text-white shadow-md rounded-lg mx-6 md:mx-0'>
                <HandymanIcon fontSize="large" className="animate-bounce mt-8 text-blue-500" />
                <h2 className="text-3xl mb-2 pt-4 px-3">Tailored Maintenance Solutions</h2>
                <div className='m-5 pb-2 px-1 md:px-2 md:pb-4'>
                  <p className='mb-2 pb-2'>Our experts tailor maintenance plans to fit your specific needs and equipment. This proactive approach ensures optimal performance and extends the life of your systems.</p>
                </div>
              </div>

              <div className='flex-1 bg-gray-800 text-white shadow-md rounded-lg mx-6 md:mx-0 md:mr-10 lg:mr-16'>
                <SettingsIcon fontSize="large" className="animate-bounce mt-8 text-blue-500" />
                <h2 className="text-3xl mb-2 pt-4 px-3">Expert Repairs</h2>
                <div className='m-5 pb-2 px-1 md:px-2 md:pb-4'>
                  <p className='mb-2 pb-2'>Count on Climac's experienced technicians to diagnose issues accurately and provide efficient repairs and upgrades. We're committed to keeping your systems running at their best.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Maintenance;