import React, { useEffect } from 'react';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

import heroImage2 from '../images/aboutUs/hand.jpg';
import heroImage1 from '../images/aboutUs/collab.jpg';
import heroImage3 from '../images/aboutUs/windmill.jpg';
import heroimage4 from '../images/aboutUs/engg.jpg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function About() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
  };

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 150);
    });
  }, []);

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  return (
    <div className='pt-16 card opacity-0'>

      <Slider {...settings}>

        <div className="relative">
          <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage3} alt="Hero 3" />
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
            <h1 className="text-7xl md:text-9xl font-thin text-white text-center p-4 mb-4">
              <span className='opacity-82 font-light'> About Us</span>
            </h1>
          </div>
        </div>

        <div className="relative">
          <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage1} alt="Hero 1" />
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
            <h1 className="text-7xl md:text-9xl font-thin text-white text-center p-4 mb-4">
              <span className='opacity-82 font-light'> About Us</span>
            </h1>
          </div>
        </div>

        <div className="relative">
          <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage2} alt="Hero 2" />
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
            <h1 className="text-7xl md:text-9xl font-thin text-white text-center p-4 mb-4">
              <span className='opacity-82 font-light'> About Us</span>
            </h1>
          </div>
        </div>

        <div className="relative">
          <img className="w-full h-auto max-h-vijesh object-cover" src={heroimage4} alt="Hero 4" />
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
            <h1 className="text-7xl md:text-9xl font-thin text-white text-center p-4 mb-4">
              <span className='opacity-82 font-light'> About Us</span>
            </h1>
          </div>
        </div>
      </Slider>

      <div className='mt-10 px-7 md:text-xl'>
        <div className='my-10'>
          {/* <h1 className="text-6xl font-thin mb-6">About Us</h1> */}
          <div className='space-y-2'>
            <p className=''>We are a reputable family-run company specializing in providing top-notch cold rooms and cutting-edge refrigeration solutions tailored to various industries.</p>
            <p className=''>With an unwavering commitment to excellence, we have earned recognition as leading cold room suppliers in Cardiff and throughout the UK. </p>
            <p className=''>
              Our expertise lies in designing and constructing bespoke cold rooms, using only the highest quality materials, including superior cold room panels,
              state-of-the-art cold room doors, efficient refrigeration systems, and durable cold room flooring.
            </p>
          </div>
        </div>

        <div className='container mx-auto px-2 py-5 gap-10 grid grid-cols-1 md:grid-cols-2 lg:gap-10'>
          <div className='bg-gray-200 p-4 rounded-2xl shadow-xl'>
            <h2 className="text-4xl font-thin mb-2 p-4">Our Mission</h2>
            <div className='mx-5 md:mx-16 mb-5'>
              <p className='md:text-justify'>Climac's mission is to provide cutting-edge refrigeration, air conditioning,
                and cold room installations that not only meet the highest standards of quality but also align with our commitment to sustainability and green energy practices.
                Through continuous innovation, personalized solutions, and exemplary customer service,
                we aim to exceed our clients' expectations and build lasting relationships within the Cardiff community and beyond.
                With a focus on cost-effective and energy-efficient solutions, Climac sets itself apart as a trusted partner in creating a more environmentally conscious future for all.
              </p>
            </div>
          </div>
          <div className='bg-gray-200 p-4 rounded-2xl shadow-xl'>
            <h2 className="text-4xl font-thin mb-2 p-4">Our Vision</h2>
            <div className='mx-5 md:mx-16 mb-5'>
              <p className='md:text-justify'>Our vision at Climac is to create a greener and more sustainable future by leading the way in energy-efficient and environmentally friendly refrigeration and air conditioning solutions.
                We aspire to be recognized as the go-to family-run business in Cardiff, UK, that prioritizes customer satisfaction, craftsmanship,
                and the integration of green energy practices into our services.
              </p>
            </div>
          </div>
        </div>

        <div className='md:w-5/12 flex mx-auto'>
          <div className='flex flex-col my-8 bg-gray-200 p-4 rounded-2xl shadow-xl'>
            <h2 className="text-5xl font-thin mb-2 mt-2 p-4">Aim</h2>
            <div className='mx-5 md:mx-16 mb-5'>
              <p className='md:text-justify'>At Climac, our aim is to be the premier provider of bespoke refrigeration, air conditioning, and cold room solutions in Cardiff, UK, and beyond.
                We are dedicated to delivering innovative and sustainable services that cater to the unique needs of our clients,
                whether it's for residential, commercial, vans, trailers, or cold room installations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-gray-100 md:text-xl'>
        <p className="px-5 font-light text-3xl md:text-4xl text-red-500 mx-5 lg:mx-20 pt-10">
          "We are committed to Providing Cost-effective and Energy-efficient Solutions."
        </p>

        <div className="block sm:hidden items-center mt-3 pt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

        <div className='md:flex justify-center gap-5 shadow-lg pt-5 pb-10 md:py-10 md:pb-20'>
          <div className='flex-1 bg-blue-100 shadow-md rounded-lg mx-6 md:mx-0 md:ml-10 lg:ml-16 hover:bg-blue-200'>
            <h2 className="text-3xl mb-2 pt-4 text-blue-900 px-3">Exceptional Excellence</h2>
            <div className='m-5 pb-2 px-1 md:px-2 md:pb-4'>
              <p className='md:text-justify'>Choose Climac for unparalleled refrigeration, air conditioning, and cold storage excellence.</p>
              <p className='md:text-justify'>Our bespoke solutions are tailored to meet the unique requirements of businesses and individuals alike.</p>
              <p className='md:text-justify'>Contact us today to embark on a journey towards exceptional refrigeration and cold room solutions designed with a focus on sustainability, energy efficiency, and your specific needs. </p>
              <p className='md:text-justify'>Together, let's make a difference in the world while ensuring your comfort and success.</p>
            </div>
          </div>

          <div className='flex-1 bg-green-100 shadow-md rounded-lg mx-6 md:mx-0 hover:bg-green-200'>
            <h2 className="text-3xl mb-2 pt-4 text-green-900 px-3">Sustainable Solutions</h2>
            <div className='m-5 pb-2 px-1 md:px-2 md:pb-4'>
              <p className='md:text-justify'>As a company focused on sustainability and green energy, Climac is committed to providing cost-effective and energy-efficient solutions. </p>
              <p className='md:text-justify'> We prioritize the use of eco-friendly practices in all our services, contributing to a greener and more sustainable future.</p>
              <p className='md:text-justify'>Our comprehensive cold room refurbishment services, including maintenance and the refurbishment of refrigerated display cabinets, enhance efficiency,
                aesthetics, and safety while minimizing disruptions to clients' operations and ensuring customer satisfaction.</p>
            </div>
          </div>

          <div className='flex-1 bg-red-100 shadow-md rounded-lg mx-6 md:mx-0 md:mr-10 lg:mr-16 hover:bg-red-200'>
            <h2 className="text-3xl mb-2 pt-4 text-red-900 px-3">Collaborative Approach</h2>
            <div className='m-5 pb-2 px-1 md:px-2 md:pb-4'>
              <p className='md:text-justify'>At Climac, we firmly believe in collaborating with our clients to gain a deep understanding of their unique requirements.</p>
              <p className='md:text-justify'>Our dedicated team of experts works closely with each client to conceptualize and design cold rooms that
                seamlessly integrate with their business operations, optimizing overall efficiency and productivity.</p>
              <p className='md:text-justify'>From inception to completion, we manage every aspect of the cold room project, ensuring a hassle-free experience while solving the most pressing cold storage challenges.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
