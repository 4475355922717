import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../logo.png";
import { Twirl as Hamburger } from 'hamburger-react'

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isServicesMenuOpen, setServicesMenuOpen] = useState(false);
  const subMenuRef = useRef(null);
  const [isMobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);

  const toggleMobileSubMenu = () => {
    setMobileSubMenuOpen(!isMobileSubMenuOpen);
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const history = useNavigate();

  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const handleMouseLeave = (e) => {
    if (
      !e.currentTarget.contains(e.relatedTarget) &&
      !subMenuRef.current?.contains(e.relatedTarget)
    ) {
      setServicesMenuOpen(false);
    }
  };

  const handleMoibleMouseLeave = (e) => {
    if (
      !e.currentTarget.contains(e.relatedTarget) &&
      !subMenuRef.current?.contains(e.relatedTarget)
    ) {
      setIsMobileMenuOpen(false);
      setMobileSubMenuOpen(false);
    }
  };

  return (
    <nav className="bg-gray-900 fixed w-full z-10">
      <div className="max-w-9xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <Link to="/">
              <img className="h-12 w-28 mr-4" src={logo} alt="Logo"
                onClick={() => {
                  setIsMobileMenuOpen(false);
                  scrollToTop();
                }} />
            </Link>
          </div>
          <div className="hidden md:block flex-grow text-center">
            <div className="gap-16 inline-flex items-baseline space-x-4">
              <Link
                to="/"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-vijesh font-medium  transition duration-300 ease-in-out"
                onClick={scrollToTop}
              >
                Home
              </Link>
              <Link
                to="/about"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-vijesh font-medium  transition duration-300 ease-in-out"
                onClick={scrollToTop}
              >
                About
              </Link>


              <div>
                <Link
                  to="/services"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-vijesh font-medium transition duration-300 ease-in-out"
                  onClick={scrollToTop} onMouseEnter={() => setServicesMenuOpen(true)}
                  onMouseLeave={handleMouseLeave}
                >
                  Services
                </Link>
                {isServicesMenuOpen && (
                  <div className="absolute z-10 bg-gray-300 bg-opacity-50 backdrop-filter backdrop-blur-lg text-black rounded-md mt-1.5 w-40 transform -translate-x-8"
                    onMouseLeave={() => setServicesMenuOpen(false)}
                    ref={subMenuRef}
                  >
                    <Link
                      to="/ResidentialAC"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setServicesMenuOpen(false);
                        scrollToTop();
                      }}
                    >
                      Air Conditioning
                    </Link>
                    <Link
                      to="/CommercialRefrigeration"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setServicesMenuOpen(false);
                        scrollToTop();
                      }}
                    >
                      Refrigeration
                    </Link>
                    <Link
                      to="/ColdRooms"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setServicesMenuOpen(false);
                        scrollToTop();
                      }}
                    >
                      Cold Rooms
                    </Link>
                    <Link
                      to="/ShippingContainers"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setServicesMenuOpen(false);
                        scrollToTop();
                      }}
                    >
                      Containers
                    </Link>
                    <Link
                      to="/VansTrucks"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setServicesMenuOpen(false);
                        scrollToTop();
                      }}
                    >
                      Transport
                    </Link>
                    <Link
                      to="/Maintainance"
                      className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setServicesMenuOpen(false);
                        scrollToTop();
                      }}
                    >
                      Service & Repair
                    </Link>

                    <div className="bg-gray-400 bg-opacity-30 rounded-md">
                      <Link
                        to="/Services"
                        className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                        onClick={() => {
                          setIsMobileMenuOpen(false);
                          setServicesMenuOpen(false);
                          scrollToTop();
                        }}
                      >
                        All Services
                      </Link>
                    </div>

                  </div>
                )}
              </div>


              {/* <Link
                to="/contact"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-vijesh font-medium"
                onClick={scrollToTop}
              >
                Contact
              </Link> */}
            </div>
          </div>

          {/* Add the action button */}
          <div className="sm:block hidden items-center">
            <button
              className="rounded-full bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          {/* Add the mobile menu button */}
          <div className="md:hidden">

            <span className="sr-only">Open main menu</span>

            <div className="text-gray-300">
              <Hamburger toggled={isMobileMenuOpen} toggle={setIsMobileMenuOpen} />
            </div>
          </div>
        </div>
      </div>

      {/* Add the mobile menu ------------------------------------------------------------------------------------------------------------------------------------------------------------ */}


      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu" onMouseLeave={handleMoibleMouseLeave}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Link
            to="/"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-vijesh font-medium"
            onClick={() => {
              setIsMobileMenuOpen(false);
              scrollToTop();
            }}
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-vijesh font-medium"
            onClick={() => {
              setIsMobileMenuOpen(false);
              scrollToTop();
            }}
          >
            About
          </Link>

          <div className="relative">
            <button
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-vijesh font-medium"
              onClick={() => {
                toggleMobileSubMenu();
              }}
            >
              Services
            </button>
            {isMobileSubMenuOpen && (
              <div className="absolute z-10 bg-gray-300 bg-opacity-50 backdrop-filter backdrop-blur-lg text-black text-lg rounded-md mt-1.5 w-52 left-1/2 transform -translate-x-1/2"
                onMouseLeave={() => setServicesMenuOpen(false)}
                ref={subMenuRef}
              >
                <Link
                  to="/ResidentialAC"
                  className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setServicesMenuOpen(false);
                    setMobileSubMenuOpen(false);
                    scrollToTop();
                  }}
                >
                  Air Conditioning
                </Link>
                <Link
                  to="/CommercialRefrigeration"
                  className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setServicesMenuOpen(false);
                    setMobileSubMenuOpen(false);
                    scrollToTop();
                  }}
                >
                  Refrigeration
                </Link>
                <Link
                  to="/ColdRooms"
                  className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setServicesMenuOpen(false);
                    setMobileSubMenuOpen(false);
                    scrollToTop();
                  }}
                >
                  Cold Rooms
                </Link>
                <Link
                  to="/ShippingContainers"
                  className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setServicesMenuOpen(false);
                    setMobileSubMenuOpen(false);
                    scrollToTop();
                  }}
                >
                  Containers
                </Link>
                <Link
                  to="/Maintainance"
                  className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setServicesMenuOpen(false);
                    setMobileSubMenuOpen(false);
                    scrollToTop();
                  }}
                >
                  Service & Repair
                </Link>
                <Link
                  to="/VansTrucks"
                  className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                  onClick={() => {
                    setIsMobileMenuOpen(false);
                    setServicesMenuOpen(false);
                    setMobileSubMenuOpen(false);
                    scrollToTop();
                  }}
                >
                  Transport
                </Link>

                <div className="bg-gray-400 bg-opacity-30 rounded-md">
                  <Link
                    to="/services"
                    className="block px-4 py-2 hover:bg-gray-600 hover:text-white backdrop-blur-lg rounded-md"
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                      setServicesMenuOpen(false);
                      setMobileSubMenuOpen(false);
                      scrollToTop();
                    }}
                  >
                    All Services
                  </Link>
                </div>

              </div>
            )}
          </div>

          <div className="block md:hidden items-center">
            <button
              className="rounded-full bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out"
              onClick={() => {
                setIsMobileMenuOpen(false);
                scrollToContact();
              }}
            >
              Contact Us
            </button>
          </div>

          {/* <Link
        to="/contact"
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
        onClick={() => {
          setIsMobileMenuOpen(false);
          scrollToTop();
        }}
      >
        Contact
      </Link> */}
        </div>
      </div>
    </nav >

  );
}

export default Navbar;