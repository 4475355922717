import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Footer from "../Footer";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import vac1 from "../../images/Transport/VAC1.jpg";
import vac2 from "../../images/Transport/VAC2.jpg";
import vac3 from "../../images/Transport/VAC3.jpg";
import vac4 from "../../images/Transport/VAC4.jpg";
import vanRef from "../../images/Transport/vanRef.jpg";
import truckRef from "../../images/Transport/truckRef.jpg";
import trailerRef2 from "../../images/Transport/trailerRef2.jpg";
import truck from "../../images/Transport/truck.jpg";
import truck2 from "../../images/Transport/truck2.jpg";

const VansTrucks = () => {

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const acItems = [
    {
      original: truckRef,
      thumbnail: truckRef,
      //description: "Data Centers Cooling",
    },
    {
      original: vanRef,
      thumbnail: vanRef,
      //description: "",
    },
    {
      original: trailerRef2,
      thumbnail: trailerRef2,
    },
    {
      original: truck,
      thumbnail: truck,
    },
    {
      original: truck2,
      thumbnail: truck2,
    },
  ];

  const topContainerRef = useRef(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 250);
    });

    const scrollToTopContainer = () => {
      window.scrollTo({
        top: topContainerRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    scrollToTopContainer();
  }, []);

  return (
    <div className="flex-col items-center card opacity-0">
      <div ref={topContainerRef} className="flex flex-col items-center pt-20">
        <div className="topContainer">
          <h1 className="text-5xl font-thin md:text-7xl px-4">Transport Refrigeration and AC</h1>
        </div>

        <div className="mt-8 mb-4 mx-4 lg:max-w-3xl shadow-xl rounded-xl overflow-hidden">
          <ImageGallery
            items={acItems}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={500}
            slideInterval={6000}
            showNav={false}
            showIndex={false}
            thumbnailPosition="bottom"
          />
        </div>

        <div className="text-center text-lg md:text-xl mb-8 p-4 xl:p-14 xl:px-18">


          <p className="p-4 font-light text-xl md:text-3xl text-red-500">
            "We are committed to Providing Cost-effective and Energy-efficient Solutions."
          </p>

          <div className="block sm:hidden items-center mt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          <div className="point-container flex flex-col sm:grid sm:grid-cols-2 gap-20 p-6 md:p-10">

            <div className="point mx-3 md:mx-10">
              <img src={vanRef} alt="Commercial Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-md max-h-96  xl:w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Portable Cold Rooms</h3>
                <p className="text-justify">
                  Elevate your catering and event capabilities with Climac's portable cold rooms.
                  These innovative refrigeration solutions provide on-the-go access to precisely controlled temperatures, ensuring that your perishable items remain fresh and your events a culinary success.
                  <span className="hidden sm:block">Whether it's outdoor catering, event management, or food distribution, our portable cold rooms guarantee reliable 
                  refrigeration for various applications.</span>
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={truckRef} alt="Residential Air Conditioning" className="float-left mr-10 mb-10 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Custom Trailer Freezers</h3>
                <p className="text-justify">
                  <span className="hidden sm:block">Efficient transportation of perishable goods is crucial for your business.</span>
                  Climac's custom trailer freezers provide the ideal solution and our refrigeration systems are meticulously designed to maintain the integrity and quality of your products,
                   whether you're in the catering, logistics, or food delivery industry. 
                  With customizable options, you can trust us to deliver reliable cold chain solutions tailored to your specific needs.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={truck} alt="Office Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Personal Fridges and Freezers</h3>
                <p className="text-justify">
                  With advanced cooling technology and precise temperature control, you can trust Climac to provide optimal refrigeration solutions for your mobile operations.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={truck2} alt="Data Centre Cooling" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Service and Repair</h3>
                <p className="text-justify">
                  <span className="hidden sm:block">
                    We understand that reliable refrigeration is vital for your business operations.
                    That's why Climac offers comprehensive service and repair solutions to ensure your refrigeration systems continue running smoothly.
                  </span>
                  Our experienced technicians are ready to diagnose and fix any issues, minimizing downtime and preventing potential losses. 
                  From routine maintenance to urgent repairs, we provide nationwide coverage and 24/7 call-out service.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center bg-gray-100 rounded-2xl overflow-auto shadow-md ">
            <h1 className="pt-6 pb-4 md:pt-10 text-4xl md:text-5xl font-thin px-3">
              Vehicle Air Conditioning Units
            </h1>
            <p className="px-3 pb-6 md:px-3 md:pt-3 text-xl">
              Climac offers a wide range of vehicle air conditioning solutions for trucks, vans, and other commercial vehicles.
              <span className="hidden md:block"> Our systems are designed to provide optimal cooling performance while ensuring maximum fuel efficiency.</span>
              From long haul trucks to motorhomes, our vehicle air conditioning units are engineered to meet the demands of diverse applications.
            </p>
            <div className="flex flex-col md:flex-row justify-evenly gap-6">
              <div className="flex justify-center pb-2 md:px-3 md:py-10">
                <img src={vac3} alt="vac1" className="rounded-lg max-h-96" />
              </div>
              <div className="flex justify-center px-3 pb-2 md:px-3 md:py-10">
                <img src={vac2} alt="vac2" className="rounded-lg max-h-96" />
              </div>
              <div className="flex justify-center px-3 pb-6 md:px-3 md:py-10">
                <img src={vac1} alt="vac3" className="rounded-lg max-h-96" />
              </div>
              <div className="flex justify-center px-3 pb-6 md:px-3 md:py-10">
                <img src={vac4} alt="vac3" className="rounded-lg max-h-96" />
              </div>
              <div className="flex justify-center px-3 pb-6 md:px-3 md:py-10">
                <img src={trailerRef2} alt="vac3" className="rounded-lg max-h-96" />
              </div>
            </div>
          </div>

        </div>

      </div>
      <Footer />
    </div>
  );
};

export default VansTrucks;
