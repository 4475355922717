import React from 'react';
import { Link } from 'react-router-dom';

import Contact from './Contact';
// import Footer from './Footer';
import heroImage2 from '../images/hero-images/hero-image-2.jpg';
import heroImage1 from '../images/ac/officeAC.jpg';
import heroImage3 from '../images/hero-images/hero-image-3.jpg';
import heroImage4 from '../images/cold rooms/coldroom.jpg';
//import HomeRefrigeration from '../images/home-refrigeration.jpg';
import whiteLogo from '../images/logoWhiteMain.png';

import officeAC from '../images/ac/officeAC.jpg';
import comRef from '../images/ComRef/comRef.jpg';
import coldRoom from '../images/cold rooms/coldroom.jpg';
import containers from '../images/Transport/contRef2.jpg';
import truck from '../images/Transport/truckRef.jpg';
import trailer from '../images/service123.jpg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useEffect } from 'react';

import logo1 from '../images/logos/1.png';
import logo2 from '../images/logos/2.png';
import logo3 from '../images/logos/3.png';
import logo4 from '../images/logos/4.png';
import logo5 from '../images/logos/5.png';
import logo6 from '../images/logos/6.png';
import logo7 from '../images/logos/7.png';
import logo8 from '../images/logos/8.png';

import './Home.css';

function Home() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
  };

  const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8];

  const settingsForLogos = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 450);
    });
  }, []);

  useEffect(() => {
    const pages = document.querySelectorAll('.page');
    pages.forEach((page) => {
      page.style.opacity = 0;
      page.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        page.style.opacity = 1;
      }, 100);
    });
  }, []);

  return (
    <div className='pt-16 page opacity-0'>

      <div className="relative">
        <Slider {...settings}>
          <div className="relative">
            <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage1} alt="Hero 3" />
          </div>

          <div className="relative">
            <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage2} alt="Hero 1" />
          </div>

          <div className="relative">
            <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage3} alt="Hero 2" />
          </div>

          <div className="relative">
            <img className="w-full h-auto max-h-vijesh object-cover" src={heroImage4} alt="Hero 2" />
          </div>
        </Slider>

        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center flex-col">
          <img className="max-w-full max-h-48 md:max-h-96" src={whiteLogo} alt="Climac Logo" />
        </div>
      </div>


      <h2 className="text-3xl md:text-5xl font-thin text-center p-4 md:mb-4 pt-8 md:pt-20 z-10"> What we offer... </h2>

      <div className="container px-5 mx-auto md:my-12 md:px-14">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 gap-y-10">

          <Link to='/ResidentialAC'>
            <div className="card min-h-full bg-gray-100 rounded-lg shadow-xl p-4 hover:scale-105 transform transition duration-250 ease-in-out opacity-0"
            >
              <img src={officeAC} alt="Home Refrigeration" className='rounded-lg' />
              <h2 className="text-2xl sm:text-xl font-semibold mb-2">Commercial & Residential Air Conditioning</h2>
              <p className="text-gray-600 text-lg md:text-base">Bespoke Residential Restaurants, Retail Shops, Supermarkets, Pubs/Clubs, Data Centers, Hotel Kitchens, Dark Kitchens, Medical Industries, Food Industries, Factories and Hospitals.</p>
            </div>
          </Link>

          <Link to='/CommercialRefrigeration'>
            <div className="card min-h-full bg-gray-100 rounded-lg shadow-xl p-4 hover:scale-105 transform transition duration-250 ease-in-out opacity-0"
            >
              <img src={comRef} alt="Home Refrigeration" className='rounded-lg' />
              <h3 className="text-2xl sm:text-xl font-semibold mb-2">Commercial Refrigeration</h3>
              <p className="text-gray-600 text-lg md:text-base">Restaurants, Retail Shops, Supermarkets, Pubs/Clubs, Hotel Kitchens, Dark Kitchens, Medical Industries, Food Industries, Factories and Hospitals.</p>
            </div>
          </Link>

          <Link to="/ColdRooms">
            <div className="card min-h-full bg-gray-100 rounded-lg shadow-xl p-4 hover:scale-105 transform transition duration-250 ease-in-out opacity-0"
            >
              <img src={coldRoom} alt="Home Refrigeration" className='rounded-lg' />
              <h3 className="text-2xl sm:text-xl font-semibold mb-2">Cold Rooms</h3>
              <p className="text-gray-600 text-lg md:text-base">Bespoke Cold Rooms and Modular Cold Rooms. Designed for efficiency and reliability, our cold rooms ensure that your products stay fresh and well-preserved.</p>
            </div>
          </Link>

          <Link to='/ShippingContainers'>
            <div className="card min-h-full bg-gray-100 rounded-lg shadow-xl p-4 hover:scale-105 transform transition duration-250 ease-in-out opacity-0"
            >
              <img src={containers} alt="Home Refrigeration" className='rounded-lg' />
              <h3 className="text-2xl sm:text-xl font-semibold mb-2">Shipping Containers</h3>
              <p className="text-gray-600 text-lg md:text-base">Convert shipping containers into efficient cold storage units with our specialized refrigeration solutions.</p>
            </div>
          </Link>

          <Link to='/VansTrucks'>
            <div className="card min-h-full bg-gray-100 rounded-lg shadow-xl p-4 hover:scale-105 transform transition duration-250 ease-in-out opacity-0"
            >
              <img src={truck} alt="Home Refrigeration" className='rounded-lg' />
              <h3 className="text-2xl sm:text-xl font-semibold mb-2">Transport Refrigeration and AC</h3>
              <p className="text-gray-600 text-lg md:text-base">Optimize your on-the-go refrigeration needs with our efficient Transport refrigeration installations. Keep perishable goods fresh and ready for delivery.</p>
            </div>
          </Link>

          <Link to='/Maintainance'>
            <div className="card min-h-full bg-gray-100 rounded-lg shadow-xl p-4 hover:scale-105 transform transition duration-250 ease-in-out opacity-0"
            >
              <img src={trailer} alt="Home Refrigeration" className='rounded-lg' />
              <h3 className="text-2xl sm:text-xl font-semibold mb-2">Maintenance and Repairs</h3>
              <p className="text-gray-600 text-lg md:text-base">Ensure the longevity and optimal performance of your cooling systems with our expert maintenance and repair services. We provide 24-hour call outs and Nationwide service.</p>
            </div>
          </Link>

        </div>
      </div>

      <div>
        <h1 className='font-thin md:text-5xl p-6'> Nationwide service with 24/7 Call-Outs</h1>
        <p className="p-4 font-light text-2xl md:text-4xl text-red-500">
          "We are committed to Providing Cost-effective and Energy-efficient Solutions."
        </p>
      </div>

      <div>
        <h1 className='font-thin md:text-5xl p-6'>Companies we work with</h1>
      </div>
      <div className='p-5 px-10 md:p-10 md:px-36'>
        <Slider {...settingsForLogos}>
          {logos.map((logo, index) => (
            <div key={index} className="slider-item">
              <img src={logo} alt={`Logo ${index + 1}`} className="px-4 md:h-12" />
            </div>
          ))}
        </Slider>
      </div>

      <div>
      <div id='contact-section'></div>
        <Contact />
      </div>

      {/* <Footer /> */}
      <style>
        {`
          /* Styles for headings */
          @media only screen and (max-width: 768px) {
            h1 {
              font-size: 2rem;
              margin-bottom: 1rem;
            }
            
            h2 {
              font-size: 1.5rem;
              margin-bottom: 0.5rem;
            }
          }

          /* Styles for paragraphs */
          @media only screen and (max-width: 768px) {
            p {
              font-size: 0.8rem;
              line-height: 1.5;
              margin-bottom: 1rem;
            }
          }

          /* Styles for buttons */
          @media only screen and (max-width: 768px) {
            button {
              font-size: 1rem;
              padding: 0.5rem 1rem;
              border-radius: 0.25rem;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Home;