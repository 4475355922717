import React from 'react';
import logo from "../logo.png";

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400">
      <div className="container mx-auto px-6 py-4 flex flex-wrap justify-between">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <img className="h-16 mx-auto mb-1.5" src={logo} alt="Logo" />



          <div className="text-gray-400 mb-4">
            <p>info@climac.co.uk</p>
          </div>
          <div className="text-gray-400 mb-4">
            <p>+44 02920 105560</p>
            <p>+44 7470 544 722</p>
          </div>
          <div className="text-gray-400 mb-4">
            <p>15 Neptune Court</p>
            <p>Vanguard Way</p>
            <p>Cardiff - CF24 5PJ</p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/3 mt-6 md:mt-0">
          <h2 className="text-lg font-bold mb-2 text-gray-100">All Rights Reserved</h2>
          <p className="text-gray-400 mb-4">Copyright © Climac</p>
          <p className="text-gray-400 mb-4">Trademark ™ Climac</p>
          <p className='text-lg'>Nationwide Service.</p>
          <p className='text-lg'>Bespoke Energy Efficient Solutions.</p>
          {/* <p className='text-sm text-gray-500'>*Subject to 6 months service.</p> */}

          <div className='flex justify-center gap-2.5 lg:gap-5 m-4 animate-pulse'>
            <a href="https://www.facebook.com/profile.php?id=100093561980919" target="_blank" rel="noopener noreferrer">
              <FacebookRoundedIcon className='text-gray-100 hover:text-blue-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.instagram.com/ClimacUK" target="_blank" rel="noopener noreferrer">
              <InstagramIcon className='text-gray-100 hover:text-pink-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
              <TwitterIcon className='text-gray-100 hover:text-blue-300 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon className='text-gray-100 hover:text-blue-400 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://wa.me/+447470544722/?text=Hello, I would like to book an appointment with the Engineer." target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon className='text-gray-100 hover:text-green-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon className='text-gray-100 hover:text-red-500 cursor-pointer hover:scale-125 transform transition duration-250 ease-in-out' />
            </a>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
