import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Modal from 'react-modal';
import './ColdRooms.css';
import Footer from "../Footer";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import coldroom1 from "../../images/cold rooms/coldroom.jpg";
import coldroom2 from "../../images/cold rooms/coldroom2.jpg";
import coldroom3 from "../../images/cold rooms/coldroom12.jpg";
import coldroom4 from "../../images/cold rooms/coldroom11.jpg";
import coldroom5 from "../../images/cold rooms/coldroom5.JPG";
import coldroom6 from "../../images/cold rooms/coldroom6.JPG";
import coldroom7 from "../../images/cold rooms/coldroom7.jpg";
import coldroom8 from "../../images/cold rooms/coldroom8.JPG";

Modal.setAppElement('#root');

const ColdRooms = () => {

  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const acItems = [
    {
      original: coldroom1,
      thumbnail: coldroom1,
      //description: "Offices",
    },
    {
      original: coldroom2,
      thumbnail: coldroom2,
      //description: "",
    },
    {
      original: coldroom3,
      thumbnail: coldroom3,
      //description: "Retail Stores",
    },
    {
      original: coldroom4,
      thumbnail: coldroom4,
      //description: "Bespoke Homes",
    },
    {
      original: coldroom5,
      thumbnail: coldroom5,
      //description: "Government Buildings",
    },
    {
      original: coldroom6,
      thumbnail: coldroom6,
      //description: "Data Centers Cooling",
    },
    {
      original: coldroom1,
      thumbnail: coldroom1,
      //description: "",
    },
    {
      original: coldroom7,
      thumbnail: coldroom7,
    },
  ];

  const topContainerRef = useRef(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 250);
    });

    const scrollToTopContainer = () => {
      window.scrollTo({
        top: topContainerRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    scrollToTopContainer();
  }, []);

  return (
    <div className="flex-col items-center card opacity-0">
      <div ref={topContainerRef} className="flex flex-col items-center pb-10 pt-20">
        <div className="topContainer">
          <h1 className="text-5xl font-thin md:text-7xl px-4">Cold Rooms</h1>
        </div>

        <div className="mt-8 mb-4 mx-4 lg:max-w-3xl shadow-xl rounded-xl overflow-hidden">
          <ImageGallery
            items={acItems}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={500}
            slideInterval={6000}
            showNav={false}
            showIndex={false}
            thumbnailPosition="bottom"
          />
        </div>

        <div className="text-center text-lg md:text-xl mb-8 p-4 xl:p-14 xl:px-18">


          <p className="p-4 font-light text-xl md:text-3xl text-red-500">
            "We are committed to Providing Cost-effective and Energy-efficient Solutions."
          </p>

          <div className="block sm:hidden items-center mt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          <div className="point-container flex flex-col sm:grid sm:grid-cols-2 gap-20 p-6 md:p-10">

            <div className="point mx-3 md:mx-10">
              <img src={coldroom2} alt="Commercial Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-md max-h-96  xl:w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Bespoke Cold Room Design</h3>
                <p className="text-justify">
                  Climac specializes in designing bespoke cold rooms tailored to your unique requirements.
                  Our cold room solutions are designed to ensure efficient cooling and preservation of products.
                  Whether you need a custom-sized cold room, specific temperature controls, or modular cold room solutions, Climac has you covered.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom4} alt="Residential Air Conditioning" className="float-left mr-10 mb-10 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Hotels and Kitchens</h3>
                <p className="text-justify">
                  Climac's cold room solutions are ideal for hotels and kitchens.
                  We provide customized cold rooms that cater to the specific needs of hotels and commercial kitchens.
                  Our cold rooms are designed to maintain precise temperature levels, ensuring the freshness and quality of food and beverages.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom1} alt="Office Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Laboratory Cold Rooms</h3>
                <p className="text-justify">
                  Laboratories require precise temperature control to maintain the integrity of sensitive experiments and samples.
                  Climac's laboratory cold rooms offer the perfect solution. Our cold rooms are designed to provide accurate temperature control, ensuring that laboratory conditions remain consistent.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom8} alt="Evaporative Cooling Systems" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Modular Cold Rooms</h3>
                <p className="text-justify">
                  Modular cold rooms are essential for industries that require versatile and scalable cold storage solutions.
                  Climac offers modular cold rooms designed to meet the unique requirements of various industries.
                  Our modular cold rooms are highly efficient and flexible, allowing you to expand or customize your cold storage as needed.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={coldroom3} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Large Scale Industrial Cold Rooms</h3>
                <p className="text-justify">
                  Large-scale industrial operations demand extensive cold storage solutions to preserve products and maintain quality.
                  Climac's large-scale industrial cold rooms are designed to meet the needs of industries such as agriculture, manufacturing, and logistics.
                  Whether you require cold storage for agricultural products or industrial materials, Climac has the expertise to deliver effective solutions.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center bg-gray-100 rounded-2xl overflow-auto shadow-md ">
            <h1 className="pt-6 pb-4 md:pt-10 text-4xl md:text-5xl font-thin px-3">
              Cold Rooms gallery
            </h1>
            <p className="px-3 pb-6 md:px-3 md:pt-3 text-xl">
              Climac offers a wide range of vehicle air conditioning solutions for trucks, vans, and other commercial vehicles.
              <span className="hidden md:block"> Our systems are designed to provide optimal cooling performance while ensuring maximum fuel efficiency.</span>
              From long haul trucks to motorhomes, our vehicle air conditioning units are engineered to meet the demands of diverse applications.
            </p>
            <div className="flex flex-col md:flex-row justify-evenly">
              <button className="flex justify-center pb-2 md:px-3 md:py-10 hover:scale-110 transform transition duration-250 ease-in-out m-3 xl:m-5" onClick={() => openImage(coldroom1)}>
                <img src={coldroom1} alt="vac1" className="rounded-lg max-h-96" />
              </button>
              <button className="flex justify-center px-3 pb-2 md:px-3 md:py-10 hover:scale-110 transform transition duration-250 ease-in-out m-3 xl:m-5" onClick={() => openImage(coldroom2)}>
                <img src={coldroom2} alt="vac2" className="rounded-lg max-h-96" />
              </button>
              <button className="flex justify-center px-3 pb-6 md:px-3 md:py-10 hover:scale-110 transform transition duration-250 ease-in-out m-3 xl:m-5" onClick={() => openImage(coldroom3)}>
                <img src={coldroom3} alt="vac3" className="rounded-lg max-h-96" />
              </button>
              <button className="flex justify-center px-3 pb-6 md:px-3 md:py-10 hover:scale-110 transform transition duration-250 ease-in-out m-3 xl:m-5" onClick={() => openImage(coldroom4)}>
                <img src={coldroom4} alt="vac3" className="rounded-lg max-h-96" />
              </button>
              <button className="flex justify-center px-3 pb-6 md:px-3 md:py-10 hover:scale-110 transform transition duration-250 ease-in-out m-3 xl:m-5" onClick={() => openImage(coldroom5)}>
                <img src={coldroom5} alt="vac3" className="rounded-lg max-h-96" />
              </button>
              <button className="flex justify-center px-3 pb-6 md:px-3 md:py-10 hover:scale-110 transform transition duration-250 ease-in-out m-3 xl:m-5" onClick={() => openImage(coldroom8)}>
                <img src={coldroom8} alt="vac3" className="rounded-lg max-h-96" />
              </button>
            </div>
          </div>
        </div>

        <Modal
          isOpen={selectedImage !== null}
          onRequestClose={closeImage}
          contentLabel="Image Modal"
          className="modal modal-content transform translate-x-5 translate-y-1/2 sm:translate-x-1/3 sm:translate-y-32 bg-gray-100 bg-opacity-50 backdrop-filter backdrop-blur-lg"
          overlayClassName="modal-overlay"
        >
          {selectedImage && (
            <img src={selectedImage} alt="Full Screen" className="modal-image rounded-xl" />
          )}
          <button className="modal-close-button rounded-full bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={closeImage}>
            Close
          </button>
        </Modal>

      </div>
      <Footer />
    </div>
  );
};

export default ColdRooms;
