import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import coldRoom1 from "../../images/home-refrigeration.jpg";
import Footer from "../Footer";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import comAC from "../../images/ac/comAC.jpg";
import resAC from "../../images/ac/resAC.jpg";
import dataAC from "../../images/ac/dataAC.jpg";
import evAC from "../../images/ac/evAC.jpg";
import govAC from "../../images/ac/govAC.jpg";
import storeAC from "../../images/ac/storeAC.jpg";
import officeAC from "../../images/ac/officeAC.jpg";
import homeAC2 from "../../images/ac/homeAC2.jpg";
import hvac from '../../images/ac/hvac.jpg';
import hvac2 from '../../images/ac/hvac2.jpg';

const ResidentialAC = () => {

  const history = useNavigate();
  function scrollToContact() {
    history("/");
    setTimeout(() => {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }

  const acItems = [
    {
      original: officeAC,
      thumbnail: officeAC,
      //description: "Offices",
    },
    {
      original: hvac,
      thumbnail: hvac,
      //description: "Offices",
    },
    {
      original: hvac2,
      thumbnail: hvac2,
      //description: "Offices",
    },
    {
      original: comAC,
      thumbnail: comAC,
      //description: "",
    },
    {
      original: storeAC,
      thumbnail: storeAC,
      //description: "Retail Stores",
    },
    {
      original: resAC,
      thumbnail: resAC,
      //description: "Bespoke Homes",
    },
    {
      original: govAC,
      thumbnail: govAC,
      //description: "Government Buildings",
    },
    {
      original: dataAC,
      thumbnail: dataAC,
      //description: "Data Centers Cooling",
    },
    // {
    //   original: coldRoom1,
    //   thumbnail: coldRoom1,
    //   //description: "",
    // },
    {
      original: homeAC2,
      thumbnail: homeAC2,
    },
  ];

  const topContainerRef = useRef(null);

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => {
      card.style.opacity = 0;
      card.style.transition = 'opacity 1s ease-in-out';
      setTimeout(() => {
        card.style.opacity = 1;
      }, 250);
    });

    const scrollToTopContainer = () => {
      window.scrollTo({
        top: topContainerRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    scrollToTopContainer();
  }, []);

  return (
    <div className="flex-col items-center card opacity-0">
      <div ref={topContainerRef} className="flex flex-col items-center pb-10 pt-20">
        <div className="topContainer">
          <h1 className="text-5xl font-thin md:text-7xl px-4">Air Conditioning</h1>
        </div>

        <div className="mt-8 mb-4 mx-4 lg:max-w-3xl shadow-xl rounded-xl overflow-hidden">
          <ImageGallery
            items={acItems}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            autoPlay={true}
            slideDuration={500}
            slideInterval={6000}
            showNav={false}
            showIndex={false}
            thumbnailPosition="bottom"
          />
        </div>

        <div className="text-center text-lg md:text-xl mb-8 p-4 xl:p-14 xl:px-18">


          <p className="p-4 font-light text-xl md:text-3xl text-red-500">
            "We are committed to Providing Cost-effective and Energy-efficient Solutions."
          </p>

          <div className="block sm:hidden items-center mt-3">
            <button
              className="rounded-full animate-bounce bg-red-500 text-gray-200 px-6 py-2 shadow-md hover:scale-110 transform transition duration-250 ease-in-out" onClick={scrollToContact}>
              Contact Us
            </button>
          </div>

          <div className="point-container flex flex-col sm:grid sm:grid-cols-2 gap-20 p-6 md:p-10">

            <div className="point mx-3 md:mx-10">
              <img src={comAC} alt="Commercial Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-md max-h-96  xl:w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Commercial Air Conditioning</h3>
                <p className="text-justify">
                  During winter, heavily-insulated factory roofs help in keeping employees warm. However, in summer, this insulation traps heat, resulting in indoor temperatures surpassing outdoor temperatures by up to 10 degrees. Climac's factory air conditioning units are designed to effectively cool such environments. In fact, our systems can reduce internal temperatures by up to 16°C, fostering a healthier and more comfortable working environment for employees.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={resAC} alt="Residential Air Conditioning" className="float-left mr-10 mb-10 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Residential Air Conditioning</h3>
                <p className="text-justify">
                  Climac specializes in residential air conditioning fitting and servicing, offering a bespoke service that caters to the unique needs of homeowners.
                  Our dedicated team provides high-quality and energy-efficient solutions to ensure optimal comfort in residential properties.
                  Whether you need a new air conditioning system installed or require maintenance and servicing for your existing setup,
                  Climac delivers reliable and professional services that exceed customer expectations.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={officeAC} alt="Office Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Office Air Conditioning</h3>
                <p className="text-justify">
                  Maintaining a comfortable temperature in your office is crucial. Unpleasantly hot and stuffy environments can hinder employee productivity and make customers uneasy. At Climac, we provide a comprehensive range of office air conditioning solutions to ensure that your office always remains at the optimal temperature. Our offerings include top-quality air conditioning products sourced from leading manufacturers in the UK, ensuring reliability and superior performance.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={govAC} alt="Public Sector Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Public Sector Air Conditioning</h3>
                <p className="text-justify">
                  Climac takes pride in collaborating with councils of all sizes to assist in reducing carbon emissions and maintaining comfortable public sector buildings. Our air conditioning options for the public sector enable you to optimize your energy consumption and comply with the European Directive on the Energy Performance of Buildings. Moreover, all the air conditioning products we offer meet the mandatory Government Buying Standards, guaranteeing high standards and quality.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={evAC} alt="Evaporative Cooling Systems" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Evaporative Cooling Systems</h3>
                <p className="text-justify">
                  Inadequate cooling in factories or warehouses can make them feel like greenhouses during summer, adversely affecting stock quality, employee health, and productivity. Climac offers a range of evaporative cooling systems that utilize the natural properties of water. These systems bring in moisture from the outdoors, circulating and cooling your facility effectively.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={storeAC} alt="Retail Air Conditioning" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96 xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Retail Air Conditioning</h3>
                <p className="text-justify">
                  Creating a cool retail environment provides a welcome escape from outdoor heat and encourages customers to complete their shopping experience. Climac provides reliable retail air conditioning solutions tailored to your establishment's needs. Installing our dependable air conditioning systems ensures that your space remains comfortable throughout the year, allowing you to handle unexpected temperature fluctuations effortlessly.
                </p>
              </div>
            </div>

            <div className="point mx-3 md:mx-10">
              <img src={dataAC} alt="Data Centre Cooling" className="float-left mr-4 mb-4 sm:mb-0 rounded-lg shadow-lg max-h-96  xl:max-w-xl xl:h-96" />
              <div className="p-1">
                <h3 className="text-3xl font-thin p-4">Data Centre Cooling</h3>
                <p className="text-justify">
                  The increasing volume of data places greater demands on data centers. Climac's data center cooling solutions safeguard your servers from overheating, ensuring uninterrupted operations. We prioritize providing a healthy and comfortable working environment for your employees. With the global demand for data exchange continually growing, your cooling system needs to keep pace. At Climac, we offer a wide range of data center cooling options, providing the precision cooling required to meet your facility's needs.
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
      <Footer />
    </div>
  );
};

export default ResidentialAC;
